<template>
  <h1 class="text-center">Landing Pages</h1>
  <ul><li>
<router-link to="/landingpagenr1" class="text-center">Example Nr.1</router-link>
  </li>
  </ul>

</template>

<script>
export default {
  name: "LandingPages"
}
</script>

<style scoped>

</style>